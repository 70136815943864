body {
    margin: 0;
    overflow-x: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f9f4f4;
  }

.main {
    max-width: 700px;
    /* height: 90vh; */
    /* display: flex; */
    flex-wrap: wrap;
    align-content: center;
    margin: 20px auto;
}

.main-inner {
    background: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
    margin-bottom: 28px;
    padding: 28px;
}

/* @font-face {
    font-family: 'GT-Walsheim-Regular';
    src: local('GT-Walsheim-Regular'), url(./fonts/GT-Walsheim-Regular.otf) format('opentype');
  } */

.ct-chart {
    position: relative;
    left: -10px;
    /* height: 260px; */
}

/* .ct-chart-bar{ */
    /* height: 240px !important; */
/* } */

.ct-label {
    font-size: 15px;
    color: black;
    height:80px;
}

.ct-bar {
    stroke: #141441 !important;
}